import getEnv from "./env"

enum PRODUCT_FLAVOUR_ENUM {
  AUTOMOTIVE = "AUTOMOTIVE",
  NON_AUTOMOTIVE = "NON_AUTOMOTIVE"
}
enum PRODUCT_TYPE_ENUM {
  ESSENTIAL = "CONNECT", //ESSENTIAL
  PREMIUM = "CONNECT_AND_INTEGRATE_STANDARD", //PREMIUM
  ENTERPRISE = "ENTERPRISE"
}

export enum DGK_FEATURES_ENUM {
  ASSETS = "assets",
  POLICIES = "policies",
  PUBLICATION_TEMPLATES = "publication_templates",
  CONNECTORS_MANAGEMENT = "connectors_management",
  REST_API_UPLOAD = "rest_api_upload",
  OFFER = "offer",
  CONTRACTS_MANAGEMENT = "contracts_management", // this is for allowing negotiation of contracts [negotion-button/cancel-contract-button]
  AGREEMENTS_MANAGEMENT = "agreements_management", // this is for display of agreements [consumer/provider]
  DOWNLOAD_HISTORY = "download_history"
}
// DGK features
export const DGK = [
  // DGK_FEATURES_ENUM.ASSETS,
  DGK_FEATURES_ENUM.POLICIES,
  DGK_FEATURES_ENUM.PUBLICATION_TEMPLATES,
  DGK_FEATURES_ENUM.CONNECTORS_MANAGEMENT,
  DGK_FEATURES_ENUM.REST_API_UPLOAD,
  DGK_FEATURES_ENUM.OFFER,
  DGK_FEATURES_ENUM.CONTRACTS_MANAGEMENT,
  DGK_FEATURES_ENUM.AGREEMENTS_MANAGEMENT,
  DGK_FEATURES_ENUM.DOWNLOAD_HISTORY
]

export enum DIGITAL_TWIN_FEATURES_ENUM {
  CSV_FILE_UPLOAD = "csv_file_upload",
  JSON_FILE_UPLOAD = "json_file_upload",
  CUSTOM_TABLE_ENTRIES = "custom_table_entries",
  REST_API_UPLOAD = "rest_api_upload",
  BROWSE = "browse",
  USE_CASES = "use_cases",
  APPLICATIONS = "applications"
}
export const DT = [
  DIGITAL_TWIN_FEATURES_ENUM.CSV_FILE_UPLOAD,
  DIGITAL_TWIN_FEATURES_ENUM.JSON_FILE_UPLOAD,
  DIGITAL_TWIN_FEATURES_ENUM.CUSTOM_TABLE_ENTRIES,
  DIGITAL_TWIN_FEATURES_ENUM.REST_API_UPLOAD,
  DIGITAL_TWIN_FEATURES_ENUM.BROWSE,
  DIGITAL_TWIN_FEATURES_ENUM.USE_CASES
  // DIGITAL_TWIN_FEATURES_ENUM.APPLICATIONS
]

export enum CX_CORE_FEATURES_ENUM {
  EDIT_MODELS = "edit_models",
  CONVERTERS = "converters",
  CROSS_MAPPINGS = "cross_mappings",
  JOBS = "jobs"
}
const CX_CORE = [
  CX_CORE_FEATURES_ENUM.EDIT_MODELS,
  CX_CORE_FEATURES_ENUM.CONVERTERS,
  CX_CORE_FEATURES_ENUM.CROSS_MAPPINGS,
  CX_CORE_FEATURES_ENUM.JOBS
]

const FeatureMapping: {
  [key: string]: {
    [key: string]: string[][]
  }
} = {
  [PRODUCT_TYPE_ENUM.ESSENTIAL]: {
    [PRODUCT_FLAVOUR_ENUM.NON_AUTOMOTIVE]: [DGK],
    [PRODUCT_FLAVOUR_ENUM.AUTOMOTIVE]: [DGK]
  },
  [PRODUCT_TYPE_ENUM.PREMIUM]: {
    [PRODUCT_FLAVOUR_ENUM.AUTOMOTIVE]: [DGK, DT, CX_CORE]
  },
  [PRODUCT_TYPE_ENUM.ENTERPRISE]: {
    [PRODUCT_FLAVOUR_ENUM.AUTOMOTIVE]: [DGK, DT, CX_CORE]
  }
}

type Features = CX_CORE_FEATURES_ENUM | DIGITAL_TWIN_FEATURES_ENUM | DGK_FEATURES_ENUM

// export function featureCheck(features: Features) {
export function featureCheck(feature: Features) {
  const currentProductFlavour = getEnv("VITE_PRODUCT_FLAVOUR") as string // Accessing the environment variable
  const currentProductType = getEnv("VITE_PRODUCT_TYPE") as string // Accessing the environment variable
  let featureList: Features[] = FeatureMapping[currentProductType][
    currentProductFlavour
  ].flat() as unknown as Features[]

  // Check if all the features are included in the selected feature list
  return featureList.includes(feature)
}
