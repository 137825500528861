import type { RouteRecordRaw } from "vue-router"
import { routePaths } from "../routePaths"

const manageRoutes: RouteRecordRaw[] = [
  {
    path: "/manage",
    name: "manage",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: routePaths.jobs.path,
        name: routePaths.jobs.pathName,
        component: () => import("@/modules/monitor/jobs/pages/Jobs.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Maintain",
              translationKey: "sideMenu.monitoring",
              routeName: ""
            },
            {
              title: "Jobs",
              translationKey: "sideMenu.jobs"
            }
          ]
        }
      }
    ]
  }
]

export default manageRoutes
