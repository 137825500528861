{
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "dihSubTitle": "Extract value from data securely",
    "logout": "Log out"
  },
  "userLogoutAlert": {
    "title": "Are you sure you want to log out?",
    "back": "Back",
    "logout": "Log out"
  },
  "sideMenu": {
    "home": "Home",
    "collapse": "Collapse sidebar",
    "help": "Help",
    "prepare": "Prepare",
    "model": "Data model",
    "mapping": "Data mapping",
    "converter": "Data converter",
    "provide": "Provide",
    "publishData": "Publish data",
    "assets": "Assets",
    "policyPublicationTemplate": "Policy publication template",
    "uploadHistory": "Asset upload history",
    "consume": "Consume",
    "searchDataOffers": "Search data offers",
    "negotiatedDataOffers": "Negotiated data offers",
    "downloadHistory": "Asset download history",
    "govern": "Govern",
    "myPolicies": "My policies",
    "createPolicies": "Create Policies",
    "policyTemplate": "Policy Templates",
    "manageAndConfig": "Configure",
    "connections": "Manage connectors",
    "jobs": "Process logs",
    "monitoring": "Monitor",
    "licences": "Licences",
    "contractDefinitions": "Contract definitions",
    "providedAgreements": "Provided contract agreements",
    "incomingAgreements": "Incoming data offers",
    "consumedAgreements": "Consumed contract agreements",
    "consumeAutomation": "Consume policy automation",
    "networkAnalytics": "Network analytics",
    "otherLogs": "Other logs",
    "digitalTwin": "Digital twin browser",
    "applications": "Applications",
    "pcfExchange": "PCF exchange",
    "pcfRequests": "PCF data requests",
    "publicationTemplates": "Publication templates"
  },
  "dropdown": {
    "selectAll": "Select All",
    "noOption": "No options available",
    "noSuchOption": "There is no such option ..."
  },
  "common": {
    "start": "Start",
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email address",
    "phoneNumber": "Phone number",
    "contactPerson": "Contact person",
    "welcome": "Welcome",
    "street": "Street",
    "city": "City",
    "country": "Country",
    "postCode": "ZIP code",
    "number": "Number",
    "next": "Next",
    "prev": "Back",
    "alreadyAccount": "Already have an account?",
    "login": "Log in",
    "loginNow": "Log in now",
    "register": "Register",
    "home": "Home",
    "submit": "Submit",
    "attention": "Attention",
    "desc": "Description",
    "website": "Website",
    "contact": "Contact",
    "details": "Details",
    "plan": "Plan",
    "listView": "List view",
    "gridView": "Grid view",
    "firstPage": "To the first page",
    "previousPage": "To the previous page",
    "nextPage": "To the next page",
    "lastPage": "To the last page",
    "download": "Download",
    "delete": "Delete",
    "edit": "Edit",
    "information": "Information",
    "organization": "Organization",
    "cancel": "Cancel",
    "save": "Save",
    "upload": "Upload",
    "link": "Link",
    "view": "View",
    "search": "Search",
    "searchTerm": "Search",
    "selectOption": "Select an option",
    "sortBy": "Sort by",
    "dataspace": "Dataspace",
    "select": "Select",
    "date": "Date",
    "title": "Title",
    "back": "Back",
    "startTyping": "Start typing",
    "useCases": "Use cases",
    "done": "Done",
    "enterValue": "Enter a value",
    "selectValue": "Select a value",
    "selectDate": "Select a date",
    "refresh": "Refresh",
    "searchByName": "Search by name",
    "close": "Close",
    "copyToClipboard": "Copied to clipboard!"
  },
  "genericError": "Oh, something went wrong! Please try again.",
  "validation": {
    "fieldRequired": "{field} is required.",
    "fieldNumeric": "The field {field} may contain only numbers.",
    "fieldAlpha": "The field {field} may contain only letters, _ and -.",
    "fieldAlphanumeric": "The field {field} may contain only letters, numbers, _ and -.",
    "fieldNumericDash": "The field {field} may contain only numbers and -.",
    "fieldHouseNumber": "The field {field} may contain only numbers, letters, and the characters - and /.",
    "fieldPhoneNumber": "The field {field} may contain only numbers and -. It must start with + or 00.",
    "fieldAlphaDash": "The field {field} may contain only letters and -.",
    "exceptHTMLTagBrackets": "The field {field} must not contain the following characters: ^<>",
    "isMaxLength": "exceeds the maximum length of 10 characters.",
    "emailFormat": "Email address has wrong format",
    "fileSizeError": "The file size is bigger than {size} MB.",
    "csvFileSizeError": "This file is too big. You can upload file up to {size} MB.",
    "csvFileExtensionError": "Only files with the csv extention are allowed.",
    "csvFileIsEmptyError": "This file is Empty. You need to upload atleast {size} KB file.",
    "csvFileFormatError": "The file does not match the expected format. Please use provided template and try again.",
    "uploadFails": "upload failed",
    "fileExtensionError": "The file extension is not suitable",
    "fileIsMalwareInfectedError": "This file seems to contain malwares. For security reasons this file cannot be uploaded. Please check your file again",
    "customValidation": "The {field} has the wrong format.",
    "selectCountryFirst": "Please select Country name first",
    "validationPostCodeCaseDE": "The field has the wrong format. It must contain 5 digits, e.g., 53113",
    "validationAlphanumericCase": "The field may contain only alphanumeric characters, dashes, and underscores. The value must start with an alphanumeric character.",
    "validationNumericDashCase": "The field may contain only numbers and -.",
    "url": "Please enter a valid URL",
    "password": {
      "passwordStrength": "Password strength",
      "weak": "Weak",
      "average": "Average",
      "strong": "Strong",
      "passwordValidationMoreChar": "{count} or more characters",
      "passwordUpperAndLowerChar": "Upper and lower case characters",
      "passwordAtLeastNumber": "At least one number",
      "passwordAtLeastSpecialChar": "At least one special character (except <>&\"`[])",
      "passwordDiffFromPrev": "Differs from your previous passwords"
    }
  },
  "footer": {
    "tagLine": "Let's power higher performance",
    "imprint": "Imprint",
    "imprintShort": "Imprint",
    "imprintLink": "https://dih.telekom.com/en/imprint",
    "contact": "Contact",
    "contactLink": "https://dih.telekom.com/en/contact",
    "termsAndConditions": "Terms & Conditions",
    "privacy": "Privacy policy",
    "privacyShort": "Privacy policy",
    "telekom": "T-Systems International GmbH. All rights reserved.",
    "telekomShort": "T-Systems International GmbH",
    "note": "Telekom Data Intelligence Hub - For Business Customers only",
    "brandedNote": "Powered by Telekom Data Intelligence Hub",
    "help": "Help",
    "cookieSettings": "Cookie settings",
    "cookieSettingsShort": "Cookies",
    "life": "Life is for sharing"
  },
  "cookieSettings": {
    "collapsedTitle": "Privacy settings",
    "collapsedText": "This website uses cookies and similar technologies. These are small text files that are stored and read on your computer. By clicking on '{0}', you accept the processing, the creation of individual user profiles across websites and partners, and the transfer of your data to third parties, some of whom process your data in countries outside the European Union (GDPR Art. 49). Details can be found in section'2 of the {1}. The data is used for analysis, retargeting and for playing out personalized content and advertising on Telekom sites and third-party sites. Further information, including information on data processing by third-party providers and the possibility of revocation, can be found in the settings and in our privacy information. Here you can {2} only with the necessary tools.",
    "extendedTitle": "Privacy settings",
    "extendedText": "In order to provide you with an optimal website experience, we use cookies. These include cookies for the operation and optimization of the site as well as for services such as text or video chat and for advertising based on your online usage behavior. This allows us, for example, to detect if you visit our pages repeatedly from the same device. We would like to give you the choice which cookies you allow:",
    "policyLinkHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkCookieHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkTextShort": "privacy information",
    "policyLinkText": "Privacy information",
    "rejectLinkText": "continue",
    "acceptButton": "Accept all",
    "saveButton": "Save settings",
    "settingsButton": "Change settings",
    "showMore": "Show more",
    "showLess": "Show less",
    "categoryTitleEssential": "Required cookies",
    "categoryTitleAnalytics": "Analytical cookies",
    "categoryTitleMarketing": "Marketing cookies",
    "categoryTitleThirdParty": "Services by other companies (independent third party providers)",
    "categoryTextEssential": "These cookies are required to enable you to navigate through the websites and use key functions. They support basic functions, such as order processing in the online shop and access to secured areas of the web page. They also serve the purpose of performing an anonymous analysis of user patterns, which we use to continuously develop and improve our web pages for you.",
    "categoryTextAnalytics": "These cookies help us to improve our understanding of user behavior. Analysis cookies allow for the compilation of usage and identification data by the original provider or third party providers into pseudonymous usage profiles. We use analysis cookies e.g. to determine the number of individual visitors to a web page or a service, to collect statistical data on the performance of our products and to analyze the visitors' usage patterns and visitor interactions on the basis of anonymous and pseudonymous information. This information cannot be traced back to a person.",
    "categoryTextMarketing": "These cookies and similar technologies are used to enable the display of personalized and therefore relevant marketing content. Marketing cookies are used to display interesting advertising content and to measure the effectiveness of our campaigns. This happens not only on Telekom websites, but also on other advertising partner sites (third-party providers). This is also called retargeting. It is used to create pseudonymous content or ad profiles, to place relevant advertising on other websites, and to derive insights into target groups that have viewed the ads and content. This information cannot be traced back to a person. Marketing and retargeting tools assist us in serving you advertising content that is potentially relevant for you. By suppressing marketing cookies, you will still see the same amount of advertising, but it may be less relevant to you.",
    "categoryTextThirdParty": "Telekom's websites include links to third-party service providers, who provide their services under their own responsibility. When you visit Telekom's web pages, cookies or similar technologies record data and send it to third parties, in part for Telekom's own purposes. The scope, purpose, and legal basis on which further processing is carried out for the third party's own purposes can be found in the third party's data privacy information. Information on the independent third-party providers can be found in the data privacy information."
  },
  "messages": {
    "noDataAvailable": "No data available",
    "noMatchFound": "No match found",
    "maximumFileSize": "Maximum file size",
    "suitableFileFormats": "Suitable file formats",
    "loading": "Loading...",
    "dragDropText": "or drag and drop it here"
  },
  "pageNotFound": {
    "title": "Oops! Looks like the page you are looking for doesn't exist yet.",
    "subtitle": "Check out our other resources in the meantime, which might be helpful for you!"
  },
  "helpResources": {
    "needHelp": "Need help in finding the right plan, more info, technical questions?",
    "developerDoc": "Integrator documentation",
    "developerDocDesc": "Get API documentation to get started",
    "example": "Example",
    "tutorials": "Tutorials",
    "exampleDescription": "Find use cases and demos relevant to your Industrial sector",
    "FAQ": "Frequently Asked Questions",
    "FAQDescription": "Find more common answers from billing to privacy",
    "browse": "Browse",
    "demos": "Demos",
    "demosDescription": "Find use cases and demos relevant to your Industrial sector",
    "glossary": "Glossary",
    "glossaryDescription": "Find the Terms and Definitions related to Data Intelligence Hub, IDSA, Catena-X, Gaia-X and more.",
    "contactUs": "This feature is not supported in your current subscription. Please contact us to upgrade."
  },
  "feedbackCard": {
    "title": "Need more help?",
    "contactUs": "Contact us"
  },
  "error": {
    "pageNotFound": "Page not found (404)",
    "somethingWrong": "Something went wrong"
  },
  "helpWidget": {
    "askSupport": "Ask support",
    "chatboxTitle": "Help chat",
    "chatboxSubtitle": "Telekom Data Intelligence Hub support",
    "defaultMessage": {
      "body": "Hi there, welcome to the Telekom Data Intelligence Hub support channel. Please select an option below."
    },
    "whatCanWeHelpWith": "What can we help you with today?",
    "suggestedAnswers": {
      "knowledgeBase": "Knowledge-base, documentation and FAQs",
      "contactSupport": "Contact support",
      "contactSales": "Contact sales"
    }
  },
  "pcfExchange": {
    "title": "PCF Exchange",
    "description": "Welcome to the easy to use end-to-end Product Carbon Footprint (PCF) exchange solution in Catena-X.",
    "recentRequests": "Recent PCF data requests",
    "viewAllRequests": "View all requests",
    "requestPcf": "Request PCF",
    "requests": {
      "pcfRequests": "PCF data requests",
      "reqDesc": "Use the area to manage your PCF data requests from partners.",
      "howTo": {
        "title": "Want to know more about the PCF data request management work?",
        "description": "Utilize this section to approve or reject the PCF data request from your partners. Further, view the statuses of the pcf data exchanges and gain full transparency in your PCF data chain.",
        "doc": "Want to know more about how data offer negotiation and consumption work?"
      }
    }
  },
  "publicationTemplates": {
    "title": "My publication templates",
    "deletedTemplates": "My deleted templates",
    "showDeletedTemplates": "Show deleted templates",
    "hideDeletedTemplates": "Show active templates",
    "newTemplate": "New template",
    "create": {
      "title": "Create publication template",
      "edit": "Edit publication template",
      "templateName": "Template name",
      "templateType": "{type} template",
      "create": "Create",
      "update": "Update",
      "name": "Name",
      "createdAt": "Created at",
      "modifiedAt": "Modified at",
      "country": "Country",
      "accessPolicyId": "Access policy",
      "usagePolicyId": "Usage policy",
      "id": "ID",
      "createdBy": "Created by",
      "modifiedBy": "Modified by",
      "isEnabled": "Is Enabled",
      "targetCompany": "Target company",
      "targetConnectors": "Target connectors",
      "selectCountry": "Select a country",
      "templateDetails": "Publication template details",
      "assetsCriteria": "Asset selection criteria",
      "connectorDetails": "Connector details"
    },
    "delete": {
      "title": "Delete publication template",
      "description": "Are you sure you want to delete this template?",
      "button": "Delete"
    }
  },
  "contracts": {
    "title": "Contract Agreements Details",
    "provider": {
      "description": "This is an overview of contract agreements your organization has with other dataspace participants.",
      "howItWorks": {
        "description": "Here you can find all your subscribed or negotiated data agreements across various dataspaces. You can filter the results for a particular connector or simply search with a contract agreement ID or Asset ID. You can also sort the list to view data agreements based on your requirements. Further, you can select the data offers and export them locally if you wish to.",
        "subtitle": "Want to know more about how data offer negotiation and consumption work?"
      },
      "details": {
        "title": "Provided Contract Agreements Details",
        "description": "Description",
        "content": "Content",
        "agreementDetails": "Agreement Details",
        "assetDetails": "Asset Details",
        "usagePolicy": "Usage Policy"
      }
    },
    "consumer": {
      "description": "This is an overview of contract agreements your organization has for data offers of other dataspace participants ",
      "downloadData": "Download data",
      "downloadAgreement": "Download agreement",
      "reinitiate": "Initate transfer & download data",
      "error": {
        "heading": "Sorry! Looks like you have already negotiated this contract.",
        "body": "You cannot re-negotiate an already negotiated contract. You can find the contract details blow also you can download this agreement and subcribe data."
      },
      "details": {
        "title": "Consumed Contract Agreements Details"
      },
      "howItWorks": {
        "description": "Here you can find all your subscribed or negotiated data agreements across various dataspaces. You can filter the results for a particular connector or simply search with a contract agreement ID or Asset ID. You can also sort the list to view data agreements based on your requirements. Further, you can select the data offers and export them locally if you wish to.",
        "subtitle": "Want to know more about how data offer negotiation and consumption work?"
      }
    }
  },
  "provider": {
    "provider": "Provider",

    "assets": {
      "title": "My assets",
      "offeredBy": "Offered by:",
      "offers": "Offers",
      "created": "Created",
      "noResultsFound": "No assets were found",
      "success": "Asset created successfully",
      "buttons": {
        "createOffer": "Create offer",
        "details": "View details",
        "delete": "Delete asset",
        "create": "Create asset",
        "close": "Close",
        "edit": "Edit asset",
        "update": "Update asset",
        "showDeleted": "Show deleted assets",
        "hideDeleted": "Hide deleted assets",
        "restore": "Restore asset",
        "save": "Save",
        "add": "Add",
        "saveJson": "Save JSON",
        "advanceAddition": "Advance addition"
      },
      "detailsDialog": {
        "title": "Asset details"
      },
      "createDialog": {
        "title": {
          "general": "Create new asset",
          "addProperties": "Add properties",
          "advance": "Advance property addition"
        },
        "publicProps": "Public properties",
        "privateProps": "Private properties",
        "dataAddress": "Data address",
        "json": {
          "fields": "Json fields",
          "editor": "Custom properties editor",
          "formatted": "Custom properties viewer",
          "error": "Invalid JSON:"
        },
        "fields": {
          "name": "Name",
          "id": "ID",
          "description": "Description",
          "contentType": "Content type",
          "version": "Version",
          "keywords": "Keywords",
          "language": "Language",
          "languagePlaceholder": "Select language",
          "assetType": "Asset type",
          "assetTypePlaceholder": "Select asset type",
          "keywordsPlaceholder": "Add keywords",
          "descriptionPlaceholder": "Write the offer description",
          "namePlaceholder": "Enter the offer name",
          "contentTypePlaceholder": "Content type",
          "versionPlaceholder": "Version number",
          "dataType": "Data address type",
          "dataTypePlaceholder": "Select data address type",
          "dataAddressUrl": "Base URL",
          "dataAddressUrlPlaceholder": "Enter the base URL",
          "targetCompany": "Target company",
          "targetCompanyPlaceholder": "Insert a target company",
          "country": "Country",
          "countryPlaceholder": "Insert a country",
          "key": "Key",
          "keyPlaceholder": "Enter the key",
          "value": "Value",
          "valuePlaceholder": "Enter the value"
        },
        "validation": {
          "name": "Please enter a name",
          "assetType": "Please select an asset type",
          "contentType": "Please enter a valid content type",
          "dataType": "Please select a valid data type",
          "targetCompany": "Please enter a valid target company",
          "country": "Please enter a valid country"
        }
      },
      "tags": {
        "restricted": "Acccess restricted",
        "unrestricted": "Unrestricted"
      },
      "search": {
        "title": "Search filters",
        "helperText": "Search for asset name, create date or type",
        "filters": {
          "byName": "Asset name",
          "byType": "Asset type",
          "byDate": "Creation date"
        }
      },
      "delete": {
        "title": "Delete asset",
        "description": "Are you sure you want to delete this asset?"
      }
    }
  },
  "home": {
    "title": "Connect & Integrate"
  },
  "gettingStarted": {
    "title": "Need help getting started?",
    "openWizard": "Open Wizard",
    "yourDataOffering": "Your data offering",
    "yourAssets": "Your assets",
    "yourPolicies": "Your policies",
    "preconfiguredCatalogue": "Preconfigured catalogue",
    "contractAgreements": "Contract agreements",
    "incomingAgreements": "Incoming agreements",
    "outgoingAgreements": "Outgoing agreements",
    "connectors": "Your connectors",
    "providedAgreements": "Provided agreements",
    "consumedAgreements": "Consumed agreements"
  },
  "yourConnection": {
    "title": "Your connectors",
    "viewAllConnection": "View all connectors",
    "help": "Help",
    "documentation": "Get to know your Connect & Integrate",
    "gettingStarted": "Getting started guide",
    "noUseCases": "No use cases available"
  },
  "wizardSelectionPopup": {
    "whatToLike": "What would you like to do today?",
    "prepare": "Prepare",
    "govern": "Govern",
    "provide": "Provide",
    "consume": "Consume",
    "howToProvide": "How would you like to provide data today?",
    "manual": "Manual",
    "automated": "Automated via an API",
    "selectUseCases": "Select your use case(s)",
    "selectSingleUseCase": "Select your use case",
    "selectDigitalTwin": "Select your digital twin type",
    "back": "Back",
    "next": "Next",
    "publish": "I understand the framework requirements of the dataspace and I agree to publish the data offer in the dataspace on behalf of my organization."
  },
  "createOffer": {
    "title": { "standard": "Create data offer", "pcf": "Create PCF data offer" },
    "publish": "Publish",
    "legalConfirmation": "I understand the framework requirements of the dataspace and I agree to publish the data offer in the dataspace on behalf of my organization.",
    "wizard": {
      "provisionAsset": "Provision asset",
      "dataUpload": "Data upload",
      "attachPolicy": "Attach policy",
      "publishDataOffer": "Publish data offer"
    },
    "provisionAsset": {
      "howToSelectSubmodel": "For which submodel would you like to create a data offer?",
      "selectSubmodel": "Select submodel",
      "selectUsecase": "Select a Use Case",
      "selectDigitalTwinType": "Select a digital twin type",
      "whichTypeToSelect": "Which type should I select?",
      "generalInformation": "General Information",
      "name": "Name",
      "id": "ID",
      "description": "Description",
      "contentType": "Content Type",
      "version": "Version",
      "keywords": "Keywords",
      "language": "Language",
      "location": {
        "title": "Location and addressing",
        "text": "Please enter the details of your asset"
      },
      "username": "Username",
      "password": "Password",
      "assetUrl": "Asset URL",
      "assetUrlPlaceHolder": "Please insert your asset URL",
      "clientId": "Client ID",
      "clientSecret": "Client Secret",
      "tokenUrl": "Token Url",
      "tokenUrlPlaceHolder": "Please insert your token URL",
      "provideAssets": "Provide asset",
      "selectConnectorLabel": "Select which connector(s) you would like to share your data with",
      "selectConnectorPlaceholder": "Select a connector",
      "selectRestApiType": "Select REST-API type",
      "selectedAuthMethod": "Authentication method"
    },
    "dataUpload": {
      "selectedDataModel": "Select your data upload",
      "csvUpload": {
        "title": "CSV file upload",
        "uploadFile": "Upload File",
        "uploadFileDescription": "Prepare and upload the file you would like to exchange. You can download the sample file to help you get started with the  format required for Catena-X.",
        "uploadFileDescriptionNote1": "Download the template file from the link below",
        "uploadFileDescriptionNote2": "Fill in the provided file with details of your partners",
        "uploadFileDescriptionNote3": "Upload the file in the provided area",
        "partnerValidationNote": "If you would like to learn more about partner validation, please",
        "clickHere": "click here",
        "downloadTemplate": "Download Template",
        "downloadSampleCsv": "Download Sample csv"
      },
      "jsonUpload": {
        "title": "json upload"
      },
      "tableUpload": {
        "title": "Table upload",
        "add": "Add"
      },
      "fileUpload": {
        "title": "File upload",
        "description": "csv, json"
      },
      "objectStorage": {
        "title": "Object storage",
        "description": "AWS, Azure, OTC or GCP"
      },
      "sftp": {
        "title": "SFTP Server",
        "description": "transfer via SFTP"
      },
      "apiUpload": {
        "title": "REST API",
        "description": "transfer via API",
        "authAndIdentiTitle": "Authentication and identification",
        "authAndIdentiDesc": "Please enter authentication details of your destination system",
        "selectApiType": "Select REST-API type",
        "locationAndAddrTitle": "Location and addressing",
        "locationAndAddrDesc": "Please enter the details of your asset",
        "assetUrl": "Asset URL",
        "username": "Username",
        "password": "Password",
        "clientId": "Client ID",
        "clientSecret": "Client Secret"
      }
    },
    "fileUpload": {
      "title": "Drag and drop your file here",
      "btnText": "Upload"
    },
    "attachPolicy": {
      "title": "Attach a policy to this data offer",
      "createPolicy": "Create a new policy",
      "bpnFieldTitle": "With whom do you want to exchange your data?",
      "howItWork": {
        "title": "How does it work?",
        "description": "We pre-configure the policies based on the Catena-X Use Case framework agreement. Access to your data will be allowed only for the provided Business Partner Numbers.",
        "knowMore": "Want to know more about the policies?",
        "documentation": "Documentation"
      },
      "accessPolicyTableText": "Attach an access policy to this data offer",
      "usagePolicyTableText": " Attach an usage policy to this data offer",
      "heading": {
        "title": "Attach existing policies or create new",
        "description": "Please attach both Access and Usage policy from the tables below to finish the data offer publication. You can also search for policies with policy names or create a new policy in case you don't have a desired policy yet."
      }
    },
    "publishDataOffer": {
      "dataOfferDetails": "Data offer details",
      "generalInformation": "General Information",
      "attribute": "Attribute",
      "value": "Value",
      "knowMore": "Know more about your data offer",
      "attachedDataAssets": "Attached items",
      "attachedPolicies": "Attached policies",
      "allowPolicyPublish": "I allow policy publication",
      "publish": "Publish",
      "verifiedLegalEntity": "Verified legal entity by",
      "dataOfferCreatedBy": "Data offer created by: ",
      "contactOrgAdmin": "Contact Organization Admin",
      "help": "Help",
      "attachedDataAsset": "Attached data asset",
      "attachedAccessPolicy": "Attached access policy",
      "attachedUsagePolicy": "Attached usage policy",
      "publishedConnectors": "Connectors to which this offer will be published",
      "knowMoreConnector": "Know more about your connectors",
      "knowMorePolicies": "Know more about your policies",
      "knowMoreDataAssets": "Know more about your data assets",
      "knowMoreDataOffers": "Know more about your data offer",
      "publishCheckboxLabel": "I, on behalf of my organization, agree to publish the data offer and assets in the dataspace. I confirm the usage of associated policies to govern the dataspace and associated policies and I will follow the framework of the dataspace for any operation on the offer and assets once published."
    },
    "result": {
      "success": {
        "title": "Successful request.",
        "description": "Asset successfully published. Job id is {id}"
      }
    },
    "success": {
      "title": "Your data offer is now published",
      "description": "Your data offer is now published, click the following for the next steps",
      "createOffer": "Create another data offer",
      "createOfferButton": "Create",
      "goToHome": "Go to home",
      "goToHomeButton": "Go to home",
      "uploadHistory": "Check your upload history"
    }
  },
  "policy": {
    "newPolicy": "New policy",
    "showDeletedPolicies": "Show deleted policies",
    "myDeletedPolicies": "My deleted policies",
    "hideDeletedPolicies": "Show active policies",
    "restoreAsset": "Restore",
    "publishDeletion": "Publish deletion",
    "create": {
      "title": "Create new policy",
      "edit": "Edit policy",
      "policyName": "Policy name",
      "policyType": "{type} policy",
      "accessPolicy": "Access Policy",
      "usagePolicy": "Usage Policy",
      "create": "Create and save",
      "update": "Update"
    },
    "delete": {
      "title": "Delete policy",
      "description": "Are you sure you want to delete this policy?",
      "button": "Delete"
    }
  },
  "jobs": {
    "jobs": "Jobs",
    "id": "Id",
    "name": "Name",
    "startedAt": "Started At",
    "status": "Status",
    "duration": "Duration",
    "action": "Actions",
    "jobDetails": "Process Details",
    "reload": "Reload",
    "noDetailsAvailable": "No further details available for this job"
  },
  "search-offer": {
    "pageTitle": "Search data offers",
    "filter": {
      "bpnSearch": "Search by your business partner number or a company name",
      "bpnSearchNote": "Find the data offers by searching with the company name or BPN.",
      "manufacturePartId": "Manufacturer Part Id",
      "manufacturePartIdNote": "Searches for all data offers matching the given part ID.",
      "enter": "Enter {field}",
      "connectorUrl": "Connector URL",
      "connectorUrlNote": "Search for data within specific connectors",
      "subModel": "Submodel",
      "subModelNote": "Fine-tune your search results for your desired use-case",
      "search": "Search",
      "filterValue": "filter value",
      "resetFilters": "Reset filters"
    },
    "noResult": {
      "title": "No result",
      "description": "Please search to browse available data offers in the network"
    },
    "helpCard": {
      "title": "How does it work?",
      "description": "Discover the data offer catalog of other dataspace participants. You can search, select and subscribe to available data offers in the dataspace.",
      "subtitle": "Want to know more about how data offer negotiation and consumption work?",
      "documentation": "Documentation"
    },
    "offers": {
      "downloadSelected": "Download Selected",
      "transferSelected": "Transfer Selected",
      "negotiate": "Negotiate",
      "noOffer": "No data offers are available."
    },
    "offer-details": {
      "title": "Confirm contract negotiations",
      "description": "Description",
      "details": "Details",
      "attachedPolicies": "Attached policies",
      "termsCheck": "I agree to the contract terms and I want to start contract negotiation and data exchange.",
      "negotiate": "Negotiate",
      "negotiateAndDownload": "Negotiate and download",
      "cancel": "Cancel",
      "attribute": "Attribute",
      "value": "Value",
      "endpointUrl": "Endpoint URL",
      "organization": "Organization",
      "bpnNumber": "BPN Number",
      "manufacturerPartId": "Manufacturer Part Id",
      "noOfAssets": "Number of selected assets",
      "membership": "Membership",
      "dismantlerBrands": "Dismantler Allowed Brands",
      "frameworkAgreement": "Framework Agreement",
      "dismantler": "Dismantler",
      "backToAssets": "Back to assets",
      "noPolicyMatchTitle": "Assets not negotiated or policies not identical",
      "noPolicyMatchText": "You are trying to download some assets which are not negotiated yet. Or some of the assets that you are trying to negotiate, do not have identical policies. Please select correct assets and continue.",
      "negotiateInProgress": "Negotiation and download in progress",
      "negotiateAndDownloadSuccess": "Negotiation and download completed successfully",
      "progress": "Progress",
      "fetchShell": "Fetch shell",
      "exportToCSV": "Export to CSV",
      "fetchJobFailed": "Error while fetching job details",
      "negotiateError": "Error while downloading assets",
      "dtr": "Digital twin registry",
      "edc": "Connector Assets",
      "browseDtr": "Browse DTR"
    },
    "dtr": {
      "title": "Digital twin shells",
      "shells": "Browse shells",
      "description": "You are going to automatically negotiate fetching and exporting Shells from Digital twin registry of the selected provider"
    }
  },
  "assetUploadHistory": {
    "title": "Asset upload history",
    "subtitle": "This history overviews the transactions on your companies's dataspace connectors. All activites on assets, policies and contract definitions are listed here.",
    "refresh": "Refresh",
    "processId": "Process Id",
    "providerUrl": "Provider URL",
    "createdOn": "Created on",
    "totalItems": "Total items",
    "successful": "Successful",
    "failed": "Failed",
    "startDate": "Start Date",
    "endDate": "End Date",
    "status": "Status",
    "actions": "Actions",
    "howItWork": {
      "title": "How does it work?",
      "description": "You can find the history of all your uploaded assets across different dataspaces.  You can re-download the files locally or transfer them to an external location. In case of errors, you must retry downloading or renegotiate the data offer.",
      "moreInfo": "Want to know more about upload history and what all can you do here?"
    }
  },
  "assetDownloadHistory": {
    "subtitle": "The history provides you the overview of your organization's downloaded data available from the negotiated contracts in the dataspace. If needed, you can download the data again from this history list.",
    "howItWorks": {
      "description": "You can find the history of all your downloaded assets across different dataspaces.  You can re-download the files locally or transfer them to an external location. In case of errors, you must retry downloading or renegotiate the data offer.",
      "moreInfo": "Want to know more about download history and what all can you do here?"
    },
    "downloadError": "Download unsuccessful, Please try again!",
    "viewErrors": "View errors"
  },
  "digitalTwinBrowser": {
    "noDocumentError": "No aspectDocument for current id",
    "nameNotProvided": "Name not provided",
    "fetchDigitalTwinsError": "Failed to fetch digital twins",
    "fetchAspectDocumentError": "Failed to fetch aspect document",
    "detailsOverviewModalTitle": "Aspect Details",
    "detailsTable": {
      "id": "Id",
      "idShort": "Id Short",
      "displayName": "Display Name",
      "modelUrn": "Model URN"
    },
    "shellsTable": {
      "id": "Id",
      "name": "Name",
      "manufacturer": "Manufacturer Part Id",
      "modifiedBy": "User",
      "modifiedAt": "Time"
    }
  }
}
