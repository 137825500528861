<template>
  <div class="page-not-found">
    <div class="page-not-found--header">
      <div v-if="variation === 'comingSoon'">
        <img alt="Coming soon" src="./assets/icons/coming-soon.svg" />
      </div>
      <div v-else-if="variation === 'sorry'">
        <img alt="Coming soon" src="./assets/icons/sorry.svg" />
      </div>
      <div v-else-if="variation === '404'">
        <img alt="404" src="./assets/icons/404.svg" />
      </div>
      <p>
        {{ t("pageNotFound.title") }}
      </p>
      <p>
        {{ t("pageNotFound.subtitle") }}
      </p>
    </div>
    <HelpResources />
    <FeedbackCard :type="variation" />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"

export default defineComponent({
  name: "PageNotFound",
  props: {
    type: {
      type: String,
      default: "404"
    }
  },
  components: {
    HelpResources: defineAsyncComponent(
      () => import("./components/HelpResources/HelpResources.vue")
    ),
    FeedbackCard: defineAsyncComponent(() => import("./components/FeedbackCard/FeedbackCard.vue"))
  },
  setup(props) {
    const { t } = useI18n()
    const route = useRoute()

    const variation = route.meta.variation || props.type

    return {
      t,
      variation
    }
  }
})
</script>

<style lang="scss" scoped>
@use "@/styles/variables/spacing.scss" as *;

.page-not-found {
  &--header {
    text-align: center;
    padding: $space-16 0;

    p:first-of-type {
      margin-top: $space-4;
    }
  }
}
</style>
