import type { RouteRecordRaw } from "vue-router"
import { routePaths } from "../routePaths"
import {
  DGK_FEATURES_ENUM,
  DIGITAL_TWIN_FEATURES_ENUM,
  featureCheck
} from "@/common/util/featuresUtil"

const providerRoutes: RouteRecordRaw[] = [
  {
    path: "/provider",
    name: "provider",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: routePaths.provider.assets.path,
        name: routePaths.provider.assets.pathName,

        component: () =>
          featureCheck(DGK_FEATURES_ENUM.ASSETS)
            ? import("@/modules/provide/assets-overview/pages/AssetsPage.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Provide",
              translationKey: "sideMenu.provide"
            },
            {
              title: "Assets",
              translationKey: "sideMenu.assets",
              routeName: ""
            }
          ]
        }
      },
      {
        path: routePaths.provider.configure.path,
        name: routePaths.provider.configure.pathName,
        component: () => import("@/modules/provide/create-offer/pages/ConfigureOffer.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Provide",
              translationKey: "sideMenu.provide"
            },
            {
              title: "Provide data",
              translationKey: "sideMenu.publishData",
              routeName: ""
            }
          ]
        }
      },
      {
        path: routePaths.provider.uploadHistory.path,
        name: routePaths.provider.uploadHistory.pathName,
        component: () => import("@/modules/provide/upload-history/pages/UploadHistory.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Provide",
              translationKey: "sideMenu.provide"
            },
            {
              title: "Asset upload history",
              translationKey: "sideMenu.uploadHistory",
              routeName: ""
            }
          ]
        }
      },
      {
        path: routePaths.provider.digitalTwin.path,
        name: routePaths.provider.digitalTwin.pathName,
        component: () =>
          featureCheck(DIGITAL_TWIN_FEATURES_ENUM.BROWSE)
            ? import("@/modules/provide/digital-twin-browser/pages/DigitalTwinBrowser.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Provide",
              translationKey: "sideMenu.provide"
            },
            {
              title: "Digital Twin Browser",
              translationKey: "sideMenu.digitalTwin",
              routeName: ""
            }
          ]
        }
      }
    ]
  }
]

export default providerRoutes
